/* src/assets/fonts/fonts.css */
@font-face {
    font-family: 'RetroFont';
    src: url('joystix.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'RetroFont', sans-serif;
}
